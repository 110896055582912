const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')

const timings = ['linear', 'ease', 'ease-in', 'ease-out', 'ease-in-out']

const animationDirections = [
  'normal',
  'reverse',
  'alternate',
  'alternate-reverse'
]

const generateComponent = color => {
  color = color || randomHex()
  const secondColor = randomHex()

  const timing = sample([timings])
  const direction = sample([animationDirections])

  const size = 50
  const duration = randomInt(1500, 4800)

  const fontSize = 128

  const div = {
    parentBg: '#ffffff',
    startColor: color,
    endColor: secondColor,
    transitionPoint: 50,
    height: size,
    width: size,
    animationDelay: 0,
    animationDirection: direction,
    animationDuration: duration,
    animationFillMode: '',
    animationIterationCount: 'infinite',
    animationTimingFunction: timing,
    animationName: 'color'
  }

  return {
    ...div
  }
}

module.exports = generateComponent
