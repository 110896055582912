import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'

import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomComponent from '../lib/generate/animation-color-pulse'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ div }) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '16rem',
      height: '100%',
      maxHeight: '100vh',
      backgroundColor: div.parentBg
    }}
  >
    <style>
      {`@keyframes ${div.animationName} {` +
        `0% { background-color: ${div.startColor} }` +
        `${div.transitionPoint}% { background-color: ${div.endColor} }` +
        `100% { background-color: ${div.startColor} }` +
        '}'}
    </style>
    <div
      css={{
        backgroundColor: div.startColor,
        animationName: div.animationName,
        animationDirection: div.animationDirection,
        animationDuration: div.animationDuration + 'ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: div.animtionTimingFunction,
        width: div.width + '%',
        height: div.height + '%',
        padding: 8
      }}
    >
      {' '}
    </div>
  </div>
)

const GridItem = ({ div, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '23vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <style>
        {`@keyframes ${div.animationName}-${props.index} {` +
          `0% { background-color: ${div.startColor} }` +
          `${div.transitionPoint}% { background-color: ${div.endColor} }` +
          `100% { background-color: ${div.startColor} }` +
          '}'}
      </style>
      <div
        css={{
          backgroundColor: div.startColor,
          animationName: div.animationName + '-' + props.index,
          animationDirection: div.animationDirection,
          animationDuration: div.animationDuration + 'ms',
          animationIterationCount: 'infinite',
          animationTimingFunction: div.animtionTimingFunction,
          height: '100%',
          width: '100%',
          padding: 8
        }}
      >
        {' '}
      </div>
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(16)
      .fill(0)
      .map((_, i) => {
        const baseDiv = randomComponent()
        const divWithPins = { ...baseDiv, ...pins }
        const div = {
          ...divWithPins
        }

        return (
          <GridItem index={i} key={i} onClick={() => onSelect(div)} div={div} />
        )
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Color Pulse</SidebarHeading>
      <Color
        label="Canvas Bg"
        value={value.parentBg}
        onChange={changeValue('parentBg')}
        active={pins.parentBg}
        onLock={() => onPin('parentBg', value.parentBg)}
        width={128}
      />
      <Color
        label="First Color"
        value={value.startColor}
        onChange={changeValue('startColor')}
        active={pins.startColor}
        onLock={() => onPin('startColor', value.startColor)}
        width={128}
      />
      <Color
        label="Second Color"
        value={value.endColor}
        onChange={changeValue('endColor')}
        active={pins.endColor}
        onLock={() => onPin('endColor', value.endColor)}
        width={128}
      />
      <SectionBorder my={3} />
      <H5 fontSize={0} mb={2}>
        Animation
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.animationName}
          onClick={() => onPin('animationName', value.animationName)}
        />
        <Label>Name</Label>
        <Input
          width={1}
          value={value.animationName}
          type="text"
          onChange={changeValue('animationName')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.animationDuration}
          onClick={() => onPin('animationDuration', value.animationDuration)}
        />
        <Label>Duration</Label>
        <Input
          width={1}
          value={value.animationDuration}
          min={1}
          max={8000}
          steps={1}
          type="range"
          onChange={changeValue('animationDuration')}
        />
        <Input
          width={1}
          value={value.animationDuration}
          type="number"
          maxWidth={96}
          ml={2}
          onChange={changeValue('animationDuration')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.animationTimingFunction}
          onClick={() =>
            onPin('animationTimingFunction', value.animationTimingFunction)
          }
        />
        <Label>Timing Function</Label>
        <select
          value={value.animationTimingFunction}
          onChange={changeValue('animationTimingFunction')}
        >
          <option>linear</option>
          <option>ease-in</option>
          <option>ease-out</option>
          <option>ease-in-out</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.transitionPoint}
          onClick={() => onPin('transitionPoint', value.transitionPoint)}
        />
        <Label>Transition</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.transitionPoint)}
          type="range"
          onChange={changeValue('transitionPoint')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.transitionPoint}
        />
      </Flex>

      <SectionBorder my={3} />
      <H5 fontSize={0} mb={2}>
        Size
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.height}
          onClick={() => onPin('height', value.height)}
        />
        <Label>Height</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.height)}
          type="range"
          onChange={changeValue('height')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.height}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.width}
          onClick={() => onPin('width', value.width)}
        />
        <Label>Width</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.width)}
          type="range"
          onChange={changeValue('width')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.width}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Css</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          `@keyframes ${value.animationName} {\n` +
          `0% { background-color: ${value.startColor} }\n` +
          `${value.transitionPoint}% { background-color: ${value.endColor} }\n` +
          `100% { background-color: ${value.startColor} }\n` +
          '}\n\n' +
          '.animation-color-pulse { \n' +
          `  animation-name: ${value.animationName};\n` +
          `  animation-direction: ${value.animationDirection};\n` +
          `  animation-duration: ${value.animationDuration}ms;\n` +
          `  animation-iteration-count: ${value.animationIterationCount};\n` +
          `  animation-timing-function: ${value.animationTimingFunction};\n` +
          '}'
        }
      />
    </>
  )
}

export default ({ initialDiv, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textImageVotes')) || []
  } catch (e) {}

  const [div, setDiv] = useState(initialDiv || randomComponent())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const divWithPins = { ...div, ...pins }
  const fullDiv = {
    ...divWithPins
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textImages')
      .collection('votes')
      .add({
        up: true,
        data: fullDiv,
        metadata: { pins }
      })

    localStorage.setItem(
      'textImagesVotes',
      JSON.stringify(
        [
          {
            id
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setDiv(randomComponent())
  }
  const skip = () => {
    setDiv(randomComponent())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      skip()
      //upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = div => {
    setDiv(div)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main div={fullDiv} />
        )
      }
      sidebar={
        <Sidebar
          value={fullDiv}
          pins={pins}
          onChange={(key, value) => {
            setDiv({
              ...div,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              //padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center'
              //borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            {false && (
              <Link
                to="/div/votes"
                css={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span css={{ paddingLeft: 10 }}>Previous likes</span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton width="50%" title="Next" onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton width="50%" title="Grid view" onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
